import * as React from 'react'
import { useEffect, useState, useLayoutEffect } from 'react'
import Layout from '../../components/Layout/Layout'

// markup
const Docs = () => {
  useLayoutEffect(() => {
    var s = document.createElement('script')
    s.setAttribute(
      'src',
      'https://cdn.jsdelivr.net/npm/redoc@latest/bundles/redoc.standalone.js'
    )
    s.onload = () => console.log('redoc script added')
    document.body.appendChild(s)
  }, [])

  return (
    <Layout>
      <title>Draft - Consupedia Reitan Api Documentation</title>
      <section className="docs">
        <redoc spec-url={'/collection_reitan.yml'}></redoc>
      </section>
    </Layout>
  )
}

export default Docs
